<template>

   <div class=" ">
  <auth-actions/>
   </div>
</template>

<script>
import AuthActions from './AuthActions.vue';
export default {
  components: {  AuthActions },
   data() {
    return {
      show:false,
     
    };
  },
   methods:{
    close(){
     return  this.show= false;
    }
   }
};
</script>

<style scoped>

.cursor{
  cursor: pointer;
}

</style>