<template>
  <div
    class="flex place-items-center md:flex-row-reverse justify-center flex-col pl-16"
  >
    <div class="grid grid-cols-3 gap-0">
      <div class="col-span-2 md:justify-start md:mx-auto mb-6">
        <div class="relative">
          <input
            type="text"
            placeholder="ابحث عن مسلسل "
            class="py-1 px-1 w-60 text-white rounded-md border-color sm:text-lg focus:ring-0 focus:border-color xs-360:mr-12 bg-sub"
            v-model="text"
            dir="rtl"
          />
          <svg
            @click="handleChange"
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 absolute left-3 top-1.5 cursor-pointer" 
            fill="none"
            viewBox="0 0 24 24"
            stroke="#9D2890"
            stroke-width="2"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
      </div>
      <div class="ml-8 md:-ml-2 md:mt-2"></div>
    </div>
    <!-- order by -->
    <div class="mb-4 ml-2">
      <div class="grid grid-cols-3 w-full gap-x-1">
        <div class="">
          <select
            id="order-by-one"
            class="order-btn  border-color border-2 text-white text-sm rounded-md focus:ring-pink-500 focus:border-pink-500 block text-right py-1 md:w-36 xs-360:w-24 xxs:w-28 w-32 px-2 select-2 -mx-1"
            page
            @change="handleChange()"
            v-model="orderBy"
          >
            <option dir="rtl" disabled :value="test" selected>ترتيب</option>

            <option dir="rtl" :value="desc">ابجديا</option>

            <option dir="rtl" :value="view_count">الاكثر مشاهدة</option>
            <option dir="rtl" :value="createdAt">الاحدث</option>
          </select>
        </div>

        <div class="" v-show="genresData">
          <select
            id="order-by"
            class="order-btn border border-color border-2 text-white text-sm rounded-md focus:ring-pink-500 focus:border-pink-500 block text-right py-1 md:w-36 xs-360:w-28 w-32 px-2 mr-3"
            @change="handleChange()"
            v-model="genreId"
          >
            <option dir="rtl" disabled :value="test">التصنيف</option>
            <option dir="rtl" :value="all">الكل</option>

            <option
              dir="rtl"
              v-for="genre in genresData"
              :key="genre.id"
              :value="`${genre.id}`"
              class="text-center"
            >
              {{ genre.title }}
            </option>
          </select>
        </div>
        <div class="mr-2 md:mr-8">
          <!-- <p class="text-lg font-medium text-color text-center mt-2">ترتيب</p> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useGenresStore } from '@/stores/genres'
import { mapActions, mapState } from 'pinia'
export default {
  data () {
    return {
      text: '',
      test: ' ',
      all: 'all',
      genreId: ' ',
      orderBy: 'createdAt',
      view_count: 'view_count',
      desc: '',
      order: null,
      createdAt: 'createdAt',
      type: ''
    }
  },
  async created () {
    await this.showGenresData()
  },
  computed: {
    ...mapState(useGenresStore, ['genresData'])
  },

  methods: {
    ...mapActions(useGenresStore, ['showGenresData']),
    handleChange () {
      this.$emit(
        'customChange',
        this.text,
        this.genreId,
        this.type,
        this.order,
        this.orderBy
      )
    }
  }
}
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
select {
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: left 0rem center;
  background-size: 1.8em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
