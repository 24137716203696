import gql from "graphql-tag";

export const getLives = gql`
query Query($page: Int!, $limit: Int!) {
              getLives(page: $page, limit: $limit) {
                  meta {
                  total
                  per_page
                  current_page
                  last_page
                  first_page
                  first_page_url
                  last_page_url
                  next_page_url
                  previous_page_url
                  }
                  data {
                  id
                  key
                  streaming_url
                  
                  desc
                  published
                  createdAt
                  updatedAt
                  }
              }
              }`

//single live data
export const getlive=gql`
    query Query($getLiveId: ID!) {
        getLive(id: $getLiveId) {
        id
        key
        streaming_url
        cover
        desc
        published
        }
        }`