import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'flowbite';
import './index.css'
import "../public/videojs/mockup/videojs.min.css"
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import './index.css'
import 'maz-ui/styles' // or import 'maz-ui/css/main.css'
// Define your global toast options
const options = {
    // Close toast after 5000ms
    timeout: 5000,
    // Position of the toast
    position: 'top-center',
    // Allow dismissing the toast by clicking on it
    closeOnClick: true,
    // Display the close button on the toast
    showCloseButtonOnHover: true,
    // Pause the timer when the cursor is over the toast
    pauseOnHover: true,
    // Use your custom toast container class
    containerClassName: 'custom-toast-container',
    // Other global options...
};
const pinia = createPinia()
createApp(App).use(pinia).use(Toast, options).use(router).mount('#app')
