<template>
    <transition >
      <div
        class="carousel-item w-full h-full bg-image absolute top-0 left-0 object-cover z-0 bg-center bg-cover fade-in-image  rounded-md
         "
        v-show="currentSlide === index"
        @mouseenter="$emit('mouseenter')"
        @mouseout="$emit('mouseout')"
        @touchstart="$emit('swipeDirection',$event)"
        :style="{ backgroundImage: `url(${image(slide.image)})`,  }"
       
      >
              <div class="" >
               
           <img
        src="@/assets/shadow.png"
        class="
          block
          absolute
          bg-repeat-x
          top-1/2
          left-1/2
          w-full
          -translate-x-1/2 -translate-y-1/2
          object-cover
          h-full
          md:h-96
          rounded-sm
        "
        alt="image"
        style="width:100%; "
        width="100"
        height="100"
      />
        </div>
           <div class="absolute text-right right-0 bottom-8 m-2 mr-6">
        <!-- <h5 class="text-1xl z-20 font-bold text-white mr-6 z-40 text-lg"> {{slide.title}}</h5> -->
       
      
      </div>
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    emits: ["mouseenter", "mouseout", "swipeDirection"],
    props: ["slide", "currentSlide", "index", "direction"],
    data(){
      return{
        s3: "https://d1p7gwkjs434q8.cloudfront.net/sliders/",
      }
    },
    computed: {
      transitionEffect() {
        return this.direction === "right" ? "slide-out" : "slide-in";
      },
    },
      methods:{
         image(image) {
          
       return this.s3+image;
      },
      }
  };
  </script>
  
  <style scoped>
  .carousel-item {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  /* .slide-in-enter-active,
  .slide-in-leave-active,
  .slide-out-enter-active,
  .slide-out-leave-active {
    transition: all 0.7s ease-in-out;
  }
  .slide-in-enter-from {
    transform: translateX(-100%);
  }
  .slide-in-leave-to {
    transform: translateX(100%);
  }
  .slide-out-enter-from {
    transform: translateX(100%);
  }
  .slide-out-leave-to {
    transform: translateX(-100%);
  } */
  .btn-bg {
    background-color: #9d2890;
  }
  .fade-in-image {
    animation: fadeIn 1000ms;
    -webkit-animation: fadeIn 1000ms;
    -moz-animation: fadeIn 1000ms;
    -o-animation: fadeIn 1000ms;
    -ms-animation: fadeIn 1000ms;
  }
  
  @keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-moz-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-webkit-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-o-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @-ms-keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
  }
  </style>
  <!--   :style="{ backgroundImage: `url(${image(slide.asset.thumbnail)})` }" -->