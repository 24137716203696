<template>
    <div class="md:container ">
      <div class="">
   <audio-player/>
      </div>
    </div>
   </template>
   
   <script>
import AudioPlayer from '@/components/videojs/AudioPlayer.vue'
   export default {
     components: {AudioPlayer  },
   
   }
   </script>
   
   <style>
   
   </style>