<template>
    <div
      class="
      relative
      md:max-h-80
      max-h-72
      bg-shimmer
      duration-400
      animate
      rounded-md
      shimmer
   
    "
    style="height: 90%"
    >
    <img
        class="rounded-lg object-cover w-full h-full shadow-lg z-40 opacity-0"
        src="@/assets/default.webp"
        width="100"
        height="100"
        alt="loader"
        style=""
        loading="lazy"
      />
      <div class="absolute right-5 md:right-6 top-2" v-show="show">
        <div class="flex justify-between space-between h-5">
          <div
            class="
              align-baseline
              nowrap
              rounded
              text-center
              py-0.3
              text-sm
              px-1
              w-16
              inline
              font-normal
              bg-slate-300
              md:text-md
              text-white
            "
          ></div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "LoaderView",
    props: {
      item: {
        type: Number,
      },
      show: {
        type: Boolean,
        default: true,
      },
    },
  };
  </script>
  
  <style scoped>
  
  .bg-shimmer{
  background: gray;
}
.shimmer {
  color: grey;
  display:inline-block;
  -webkit-mask:linear-gradient(-60deg,rgb(228, 212, 212) 30%,rgba(224, 221, 221, 0.333),rgb(255, 246, 246) 70%) right/300% 100%;
 
  animation: shimmer 1.5s infinite;
  font-size: 50px;
  max-width:208px;
}

@keyframes shimmer {
  100% {-webkit-mask-position:left}
}
  </style>