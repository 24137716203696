<template>
  <div class="lg:container mb-4">
    <HomeLayout :data="false"> </HomeLayout>
    <!-- sart of title -->
    <div class="mt-8 mb-5">
      <p class="text-white text-center font-bold text-xl">تصفح كل المقاطع</p>
      <div class="border border-b-1 border-color w-40 mx-auto"></div>
    </div>
    <!-- end of title -->
    <filtter-view @custom-change="handleCustomChange" />
    <div class="max-w-screen-lg mx-auto px-4" id="infinte-scroll">
    
      <div
        v-show="!loaderState "
        class="
          grid grid-cols-2
          sm:grid-cols-3
          xs-800:grid-cols-4
          md:grid-cols-5
          ml-4
          justify-items-center
          gap-x-4
        "
        dir="rtl"
      >
      <div class="relative animate-in slide-in-from-bottom-72 duration-700" v-for="item in eposidesData" :key="item.id">
          <card-basics :item="item"  />
        </div>
       
      </div>
      <div class="absloute bottom-6 ">
            <spinner-view :loading="loadingStatus"/>
        </div>
        <!-- <div v-show="backTop" class="sticky bottom-6 left-6 w-6 h-6">
          <router-link to="#"><span class="bg-pink-600 rounded-full padding-5 w-4 text-white"> top</span></router-link>
        </div> -->
    </div>
    <div class="max-w-screen-lg mx-auto px-4">
      <div
        v-show="loaded"
        class="
          grid grid-cols-2
          sm:grid-cols-3
          xs-800:grid-cols-4
          md:grid-cols-5
          ml-2
          justify-items-center
          gap-x-4
        "
        dir="rtl"
      >
        <HomeLoader v-for="(index, item) in 5" :key="index" :item="item" />
      </div>
    </div>
  
  </div>
</template>

<script>
import HomeLayout from "@/components/HomeLayout.vue";
import FiltterView from "./FiltterView.vue";
import { mapState, mapActions } from "pinia";
import { useEposidesStore } from "@/stores/eposides";
import CardBasics from "@/components/cards/CardBasics.vue";
import HomeLoader from "@/components/cards/loaders/HomeLoader.vue";
import SpinnerView from '@/components/SpinnerView.vue';

export default {
  components: {
    HomeLayout,
    FiltterView,
    CardBasics,
    HomeLoader,
    SpinnerView,
   
  },
  data() {
    return {
      loaded: false,
      loading: false,
      loaderState: false,
      backTop:false,
      text: "",
      page: 1,
      filtterPage:1,
      limit: 15,
      fillterLimit:30
    };
  },
  computed: {
    ...mapState(useEposidesStore, ["eposidesData", "eposidesMetaData","loadingStatus"]),
  },
 

  methods: {
    ...mapActions(useEposidesStore, [
      "showEposidesData",
      "filtterData",
      "showEposidesNextData"
     
    ]),

    handleScroll() {
        window.onscroll = () => {
       let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight-50;
        if (bottomOfWindow && this.eposidesMetaData.next_page_url != null && navigator.onLine) {
        this.page++
        this.backTop=false;
          this.showEposidesNextData(this.page,this.limit);
       }else{
        return this.backTop=true;
       }
      };


    
    },
   
      async handleCustomChange(text, genreid, type="eposides", order =null, orderBy=null) {
        console.log(genreid,text)
        if(genreid=='all'){
          this.showEposidesData(this.page=1,this.limit)
         
        } else{

        
      this.loaded = true;
     this.loaderState=true;
      try {
        await this.filtterData(
          this.filtterPage,
          this.fillterLimit,
          text,
          genreid,
          type,
          order,
          orderBy
        );
        this.loaded = false;
        this.loaderState=false;
      } catch (error) {
        this.loaded = false;
        console.error("Failed to videos  data", error);
      }
    }
    },

  
  },
  mounted(){
    addEventListener('scroll',this.handleScroll,true)
  },
  async created() {
    this.loaded = true;
    this.loading = false;
 
    try {
      await this.showEposidesData(this.page, this.limit);
      

      this.loading = true;
      this.loaded = false;
      this.eposides = this.eposidesData;
    } catch (error) {
      this.loading = true;
      this.loaded = false;
      console.error("Failed to videos  data", error);
    }
    this.loaded=false;
    
  },
};
</script>

<style scoped>
.bg-sub {
  background-color: black;
}
select {
  outline: none;
  appearance: none;
  background-repeat: no-repeat;
  background-position: left 1rem center ;
  background-size: 1em;
}
</style>