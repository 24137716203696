<template>
  <ul
    v-show="share"
    class="inline-block -mb-3.5"
    :class="share ? 'animate-in slide-in-from-top duration-700' : ''"
  >
  
    <li class="text-white text-sm m-2 hover:rotate-45" @click="faceBook">
      <svg
        class="md:w-7 md:h-7 w-6 h-6 hover:cursor-pointer"
        fill="white"
        stroke="white"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          d="M24 12.073c0-6.627-5.373-12-12-12s-12 5.373-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.385H7.078v-3.47h3.047V9.43c0-3.007 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.874v2.25h3.328l-.532 3.47h-2.796v8.385C19.612 23.027 24 18.062 24 12.073z"
        />
      </svg>
    </li>
    <li class="text-white text-sm m-2 hover:rotate-45" @click="twitter">
      <svg
        class="md:w-7 md:h-7 w-6 h-6 hover:cursor-pointer"
        fill="white"
        stroke="white"
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path
          d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"
        ></path>
      </svg>
    </li>
  </ul>
</template>

<script>
export default {
  props: ["share"],
  methods: {
    shareLinks(url) {
      var left = (screen.width - 570) / 2;
      var top = (screen.height - 570) / 2;
      var params =
        "menubar=no,toolbar=no,status=no,width=570,height=570,top=" +
        top +
        ",left=" +
        left;
      window.open(url, "NewWindow", params);
    },

    faceBook() {
      const pageUrl = window.document.location.href;
      const url = "https://www.facebook.com/sharer.php?u=" + pageUrl;
      this.shareLinks(url);
    },
    twitter() {
      const pageUrl = window.document.location.href;
      const url = "https://twitter.com/intent/tweet?url=" + pageUrl;
      this.shareLinks(url);
    },
  },
};
</script>

<style>
</style>