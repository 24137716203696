

<template>
  <!-- component -->

  <div class=" md:mx-auto mb-4" v-if="serieData">
    <HomeLayout :data="true">
      <trailler-view :asset="serieData" :firstEposide="firstEposide"  />
    </HomeLayout>

    <!-- start of video info-->
    <div class="md:mr-12 mr-6">
      <video-info :serie="serieData" />
    </div>

    <!-- end of video info-->

    <!-- start of programs that you may like-->
    <div
      class="flex flex-row-reverse space-x-4 space-x-reverse mx-3 md:mr-12 mr-8"
    >
      <div>
        <h6 class=" md:text-3xl text-lg font-bold text-color mt-1">
          :الحلقات
        </h6>
      </div>
      <div>
        <p class="md:text-3xl text-lg font-bold text-white mt-1">
          {{
            serieData.eposides.length > 0 ? serieData.eposides.length : "لاتوجد"
          }}
        </p>
      </div>
    </div>
    
    <div
        v-show="loaderState || loaded"
        class=" flex flex-wrap  my-3  md:mx-12 "
        dir="rtl"
      >
      
        <horizental-view v-for="item in sortedd" :key="item.id" :item="item" :index="index" :image="serieData.eposides.image" />
    </div>
    <!-- start of grid section -->
    
     
    <!-- <div v-else>
      <div class="text-lg text-red-600 text-center">لاتوجد بيانات</div>
    </div> -->
    <SpinnerView :loading="loading" />
   
  </div>
</template>
<script>
import VideoInfo from "../components/VideoInfo.vue";
import HomeLayout from "@/components/HomeLayout.vue";
import TraillerView from "@/components/cards/TraillerView.vue";
import { mapActions, mapState } from "pinia";
import SpinnerView from "@/components/SpinnerView.vue";
import { useSerieStore } from "@/stores/serie";
import HorizentalView from '@/components/cards/HorizentalView.vue';

export default {
  components: {
    VideoInfo,
    HomeLayout,
    TraillerView,
    SpinnerView,
    HorizentalView,
  },
  data() {
    return {
      loaded: false,
      loading: false,
    };
  },
 
  computed: {
    ...mapState(useSerieStore, ["firstEposide","serieData","getEposides"]),
    sortedd(){
    
    return this.serieData.eposides.slice().sort((a,b)=> {
   return -(b.order - a.order)
    });
    
    }
  
  },
  methods: {
    ...mapActions(useSerieStore, ["ShowSerieData"]),
  },
  async created() {
    this.loading = true;
   this.loaded=false;
    try {
    
    await this.ShowSerieData(this.$route.params.id);
   
      this.loaded = true;
      this.loading = false;
    } catch (error) {
      console.error("Failed to grab portal data", error);
      this.loading = false;
      this.loaded=true;
    }
  
  },
};
</script>

<style>
</style>

