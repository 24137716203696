import { defineStore } from "pinia";

import apolloClient from "../plugins/apollo";
import { getLives,getlive } from "@/Gql/lives.js";
export const useLivesStore = defineStore("lives", {
    state: () => {
        return {
            lives: Array,
            live: Object,

        }
    },
    getters: {
        livesData: (state) => state.lives,
        liveData: (state) => state.live
    },
    actions: {
        async showLivesData() {
            await apolloClient
                .query({
                    query: getLives,
                    variables: { page: 1, limit: 6 },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.lives = res.data.getLives.data
                })
                .catch(error => {
                    this.error = error
                })



        },
        async showLive(id) {
            await apolloClient
                .query({
                    query: getlive,
                    variables: {getLiveId:id },
                    fetchPolicy: "cache-first",
                }).then(async res => {
                    this.live = res.data.getLive.data
                })
                .catch(error => {
                    this.error = error
                })
        }
    }
})