<template>
  <transition>
    <div
      class="carousel-item w-full h-full absolute top-0 left-0 object-cover z-0 bg-center bg-cover fade-in-image slider"
      v-show="currentSlide === index"
      @mouseenter="$emit('mouseenter')"
      @mouseout="$emit('mouseout')"
      :style="{ backgroundImage: `url(${image(slide)})` }"
    >
      <div class="absolute bottom-0 inset-x-0 h-44 bottom-shadow"></div>
      <!-- main description -->
      <div class="absolute psoition-item    my-2 md:mr-10">
        <p class="text-white md:text-[20px] indent-4 text-md leading-6  md:mr-6 z-20 md:w-128 w-[320px] text-position text-ellipsis">
          {{ slide.desc ? slide.desc : '' }}
        </p>
        <router-link :to="`/watch/now/${slide.serie_id}`">
          <button
            v-show="slide.desc != 'null'"
            type="button"
            class="md:mr-4 btn-bg focus:outline-none rounded-lg text-sm md:px-[30px] md:py-3 px-6 py-2 md:text-[20px] xsm:text-[16px] text-white text-center inline-flex items-center md:text-md mt-2 z-20 btn-top"
          >
            {{ 'شاهد الان' }}
            <play-svg />
          </button>
        </router-link>
      </div>
      <!-- end of main description -->
    </div>
  </transition>
</template>

<script>
import PlaySvg from '../svg/PlaySvg.vue'
export default {
  components: { PlaySvg },
  emits: ['mouseenter', 'mouseout', 'swipeDirection'],
  props: ['slide', 'currentSlide', 'index', 'direction'],
  data () {
    return {
      s3: 'https://d1p7gwkjs434q8.cloudfront.net/sliders/'
    }
  },
  computed: {
    transitionEffect () {
      return this.direction === 'right' ? 'slide-out' : 'slide-in'
    }
  },
  methods: {
    image (slider) {
      if (slider.image) {
        return this.s3 + slider.image
      } else {
        return require('@/assets/' + 'placeholder-wide.webp')
      }
    }
  }
}
</script>

<style scoped>
.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.btn-bg {
  background-color: #9d2890;
}
.fade-in-image {
  animation: fadeIn 1000ms;
  -webkit-animation: fadeIn 1000ms;
  -moz-animation: fadeIn 1000ms;
  -o-animation: fadeIn 1000ms;
  -ms-animation: fadeIn 1000ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.psoition-item{
  right: 0;
  bottom: 2rem;
  text-align: right;

}

.text-position{
  text-align: right;


}
.text-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* Number of lines to show */
}
@media only screen and (max-width: 959px) {
  .psoition-item{
  left: 50%;
  bottom: 2rem;
  transform: translateX(-50%);
  text-align: center;
}
.text-position{
  position: absolute;
  left: 50%;
  bottom: 4rem;
  transform: translateX(-50%);
  text-align: center;

}
.btn-top{
  margin-bottom: 10px;
}
}
</style>
