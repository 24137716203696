<template>
  
<div class=" relative max-w-xs card-bg rounded-lg w-36 md:w-40 mx-auto  md:w-64  ">
    <router-link :to="`/brows/live/${live.id}`">
        <img class="rounded-lg object-fit md:h-72 h-48 w-36 md:w-56 z-40" src="@/assets/s24.webp" alt="s24" width="100" height="100">
  
   
      
            <h5 class="m-2 text-lg font-bold text-center tracking-tight text-white ">{{live.host_name}} </h5>
          </router-link>       
         <div class="absolute top-2 right-2 m-1 space-between h-7">
        <div
          class="

            align-baseline
            nowrap
            rounded
            text-center
            mt-2
            mr-2
            py-0.5
            text-sm
            px-1
            inline
            font-normal
            bg-red-600
            md:text-md
            text-white
          "
        >
          بث مباشر
        </div>
      
      </div>
</div>

</template>

<script>
export default {
props:['live']
}
</script>

<style>

</style>