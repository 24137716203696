<template>
  <div class=" ">
    <slot />
    <div class="grid grid-cols-2 gap-y-6 gap-x-2 -mt-1" v-if="isActive">
      <button @click="manageAcountAction"
        class="  btn-color border border-color text-white text-center rounded-md   xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2     md:text-[20px] lg:text-[15px] xsm:text-[12px]">
        الحساب
      </button>

      <button @click="logout"
        class="border border-color  text-white text-center rounded-md   xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2   text-sm md:text-[20px] lg:text-[15px] xsm:text-[12px]">
        تسجيل خروج
      </button>
    </div>
    <div class="grid grid-cols-2 gap-y-6 gap-x-2 -mt-1 hidden md:block" v-else>
      <button @click="$router.push('/user/subscriptions')"
        class="btn-color border border-color btn-color text-white text-center rounded-md   xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2   text-sm md:text-[20px]  xsm:text-[12px]">
        اشترك الان
      </button>
      <button @click="$router.push('/user/login')"
        class="border border-color  text-white mx-2 text-center rounded-md   xsm:px-3 md:px-[30px] md:py-2.5 px-[150px] py-2   text-sm md:text-[20px] lg:text-[15px] xsm:text-[12px]">
        تسجيل  دخول
      </button>
      
    </div>
  </div>
</template>

<script>
import {ISACTIVESTRIPEUSER, AUTHTOKEN, AUTHKey, USER } from '@/constants'
import { useAuthStore } from '@/stores/auth'
import { mapActions } from 'pinia'
import { useToast } from 'vue-toastification'

export default {
  components: {
    // SpinnerView,
  },
  data() {
    return {
      user: false,
      isActive: false,
      isDone: false,
      toast: useToast()
    }
  },
   mounted() {
     this.checkIsActiveAction()
  },
  watch: {
    // whenever question changes, this function will run
    // eslint-disable-next-line
    isActive(newValue, oldValue) { }
  },
  methods: {

     checkIsActiveAction() {
      return (this.isActive = localStorage.getItem(ISACTIVESTRIPEUSER))
    },
    ...mapActions(useAuthStore, ['manageAccount']),
     manageAcountAction() {
      window.location.href="https://subscription.systech-digital.com/p/login/dR6cOh4MsfQRbxC000"
    },
    redirectUser(response) {
      if (response.data.success) {
        this.window.location.href = response.data.url
      }
    },
    logout() {
      window.localStorage.removeItem(USER),
        window.localStorage.removeItem(AUTHTOKEN),
         window.localStorage.removeItem(AUTHKey),
       window.localStorage.removeItem(ISACTIVESTRIPEUSER)
        this.isActive = false
        this.toast.info('تم تسجيل الخروج')
        this.$router.push({ name: 'home' })
        
    }
  } //end of methods
}
</script>

<style></style>
