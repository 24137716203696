<template>
  <div
    class="relative md:h-48 xsm:h-32 my-2 px-2 md:w-1/4 sm:w-1/3 w-1/2 rounded-lg"
    dir="rtl"
  >
    <router-link :to="`/categories/show/${genre.id}`">
      <img
        :src="require('@/assets/category-1.webp')"
        class="w-full h-full opacity-1 object-cover rounded-lg"
        alt="cat"
        width="100"
        height="100"
      />
      <div
        class="absolute bottom-0 inset-x-2 h-44 bottom-shadow-card rounded-lg"
      ></div>
      <div class="">
        <div class="">
          <div
            class="items-center z-20 align-baseline nowrap rounded text-center py-0.3 font-bold text-xl text-gray-200"
          >
            <p class="absolute bottom-6 right-6 md:text-[30px] text-[22px]">
              {{ getTitle(genre.title, index) }}
            </p>
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['genre', 'index'],
  data () {
    return {
      s3: 'http://',
      colors: [
        'custom-bg-F2AF29-500',
        'custom-bg-9D2890-500',
        'custom-bg-D53B3B-600',
        'custom-bg-D5573B-500',

        'custom-bg-F2AF29-500',
        'custom-bg-9D2890-500',
        'custom-bg-D53B3B-600',
        'custom-bg-D5573B-500',

        'custom-bg-F2AF29-500',
        'custom-bg-9D2890-500',
        'custom-bg-D53B3B-600',
        'custom-bg-D5573B-500'
      ],
      bg: ''
    }
  },
  methods: {
    getTitle (title, index) {
      this.bg = this.colors[index]
        ? this.colors[index]
        : this.colors[Math.floor(Math.random() * this.colors.length)]

      return title
    }
  }
}
</script>

<style scoped>
.custom-bg-D53B3B-600 {
  background-color: #d53b3b;
}
.custom-bg-9D2890-500 {
  background-color: #9d2890;
}
.custom-bg-D5573B-500 {
  background-color: #d5573b;
}
.custom-bg-F2AF29-500 {
  background-color: #f2af29;
}
</style>
