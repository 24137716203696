<template>
  <div
    :id="`basic-${index}`"
    class="rounded-lg h-full w-full relative cursor-pointer"
    :class="className"
    @click="$router.push({ name: 'watch', params: { id: item.id } })"
  >
    <div class="absolute left-3 md:left-6 md:top-3 top-1" v-show="item.label">
      <div class="flex justify-between space-between h-7">
        <div
          class="align-baseline nowrap rounded-sm text-center py-0.3 mt-2 mr-1 text-[11px] px-3 inline font-normal btn-color md:text-md text-white truncate"
          style="line-height: 1.2rem"
        >
          {{ item.label }}
        </div>
      </div>
    </div>

    <div class="absolute bottom-0 inset-x-0 h-44 bottom-shadow-card">
      <div
        class="absolute md:bottom-6 md:right-6 bottom-2 right-2 text-gray-100 md:text-[25px] text-sm"
      >
        <p class="text-gray-100 text-right text-md font-bold">{{ item.serie?.title }}</p>
        <p class="text-gray-400 text-right text-sm mt-1">{{ item.title }}</p>
      </div>
    </div>

    <img
      @click="$router.push({ name: 'watch', params: { id: item.id } })"
      ref="image"
      class="rounded-lg swiper-lazy h-full"
      :src="defaultImage"
      :data-src="image(item)"
      width="100"
      height="100"
      :alt="item.title"
      style="object-fit: cover !important; height: 100% !important"
    />
  </div>
</template>

<script>
export default {
  props: ["item", "className", "index", "serieName"],

  data() {
    return {
      s3: "https://d1p7gwkjs434q8.cloudfront.net/eposides/",
      defaultImage: require("@/assets/placeholder.webp"),
      observer: null,
    };
  },

  mounted() {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // Adjust as needed
    });

    this.observer.observe(this.$refs.image);
  },
  methods: {
    image(item) {
      if (item.image) {
        return this.s3 + item.image;
      } else {
        var images = require.context("@/assets/", false, /\.webp$/);
        return images("./" + "default.webp");
      }
    },
    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const img = entry.target;
          img.src = img.dataset.src;
          this.observer.unobserve(img);
        }
      });
    },
  },
};
</script>

<style>
img {
  pointer-events: none;
}
</style>
