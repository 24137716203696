
<template>
  <div class="flex justify-center rounded-lg mx-2 mt-12">
    <div class="">
      <div
        class="
          container
          mx-auto
          px-4
          p-1
          bg-sub
          rounded-lg
          shadow-md
          drop-shadow-2xl
          lg:p-2
          rounded-md
          place-items-center
        "
      >
        <div class="flex justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#9d2890"
            class="w-36 h-36"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        </div>
        <div class="">
          <p class="text-md text-white text-center font-bold">
            تأكيد إلغاء الإشتراك؟
          </p>
          <div class="border border-b-4 border-color mx-auto mt-1 w-36"></div>
          <div class="m-2 mt-6 w-5/6 text-center mx-auto" dir="rtl">
            <p class="text-white md:text-md text-sm">
              هل أنت متاكد من أنك تريد إالغاء إشتراكك في خدمة "ناسنا". في حالة
              إلغائك للإشتراك لن تتمكن من الإستمتاع بـ
            </p>
            <div class="flex md:justify-center m-3" dir="rtl">
              <ul>
                <li
                  class="
                    text-white
                    md:text-md
                    text-sm text-gray-400
                    mr-6
                    my-3
                    flex
                  "
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  مكتبة ضخمة من المحتوى الرقمي تحتوى على أكثر من 1500 فيديو
                </li>
                <li
                  class="
                    text-white
                    md:text-md
                    text-sm text-gray-400
                    mr-6
                    my-3
                    flex
                  "
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  مشاهدة جميع البرامج والحلقات بلا قيود ودون فواصل او إعلانات
                </li>
                <li
                  class="
                    text-white
                    md:text-md
                    text-sm text-gray-400
                    mr-6
                    my-3
                    flex
                  "
                >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                  تابع حلقاتك المفضلة على جميع الأجهزة
                </li>
                <li
                  class="
                    text-white
                    md:text-md
                    text-sm text-gray-400
                    mr-6
                    my-3
                    flex
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 ml-2"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="white"
                    stroke-width="2"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>

                  محتوى متنوع يناسب كل العائلة
                </li>
              </ul>
            </div>
          </div>
          <div class="m-3 relative md:w-96 mx-auto" dir="rtl"></div>

          <div class="flex justify-center my-6">
            <router-link to="/">
              <button
                type="submit"
                class="
                  text-white
                  focus:ring-0 focus:outline-none focus:ring-pink-300
                  rounded-lg
                  text-md
                  w-full
                  sm:w-auto
                  px-5
                  py-2.5
                  text-center
                  
                  w-btn
                  text-white
                "
                style="background-color:#d5573b;"
                @click="cancelSubscription"
              >
              إلغاء الإشتراك
              </button>
            </router-link>
          </div>
          <div class="flex justify-center my-6">
            <router-link to="/">
              <button
                type="submit"
                class="
                  text-white
                  focus:ring-0 focus:outline-none focus:ring-pink-300
                  rounded-lg
                  text-md
                  w-full
                  sm:w-auto
                  px-5
                  py-2.5
                  text-center
                  border border-color
                  w-btn
                  text-white
                "
              >
                تراجع
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import SpinnerView from "@/components/SpinnerView.vue";
  import { useAuthStore } from '@/stores/auth';
  import { mapActions } from 'pinia';
export default {
  components: {
    // SpinnerView,
  },
  computed: {},
  methods: {
    ...mapActions(useAuthStore, ["unsubscribe"]),
    async cancelSubscription(){
        await this.unsubscribe().then((response)=>{

          this.redirectUser(response)
        });
    },
    redirectUser(response){
     
    if(response.data.message=="Unsubscribe successfully" || response.data.message=="Not subscribed"){
      
      return this.$router.push({name:"unsubscribe"})
    }
    
    }
  
  },
};
</script>


<style scoped>
.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-sub {
  background-color: #181b30;
}
</style>
