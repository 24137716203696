<template>
  <footer v-if="$route.meta.footer"
    class="h-full md:h-48 relative px-1 md:p-2 content-center"
    style="background-color: #292a46; border-color: #212121"
  >
  <div class="md:hidden">
    <mobile-footer/>
  </div>
   <div class="hidden md:block">
    <desktop-footer/>
   </div>
  
  </footer>
</template>

<script>
import DesktopFooter from './DesktopFooter.vue';
import MobileFooter from './MobileFooter.vue';
export default { components: { MobileFooter, DesktopFooter } ,

};
</script>

<style scoped>
.m-right {
  margin-right: 30%;
}

footer {
  clear: both;
  position: relative;
}
.text-xss {
  font-size: 9px;
}
</style>
