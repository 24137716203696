<template>
  <div
    class="absolute carousel-item w-full h-full object-cover z-0 bg-center bg-cover"
    :style="{
      backgroundImage: `url(${image(asset)})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover'
    }"
  >
    <div class="">
      <div
        class="absolute bottom-0 inset-x-0 h-44 bg-red-600 bottom-shadow"
      ></div>
      <div class="absolute text-right right-11 bottom-1 md:bottom-3 m-2 w-46">
        <ShareIt :share="share" />
        <div class="flex justify-around items-center">
          <button
            type="button"
            class="focus:outline-none rounded-lg ms:text-md px-3 py-1 mr-12 md:px-[30px] md:py-3 xsm:px-3 md:text-[20px] xsm:text-[12px] xsm:mr-4 mb-2 text-white text-center inline-flex items-center text-sm md:text-md md:font-bold"
            style="background-color: #9d2890"
            v-if="firstEposide.id"
          >
            <router-link :to="`/watch/${firstEposide.id}`" class="text-md">
              متابعة المشاهدة
            </router-link>
            <play-svg />
          </button>

          <button class="mb-3" @click="shareApi" v-if="firstEposide.id">
            <svg
              v-show="share == false"
              xmlns="http://www.w3.org/2000/svg"
              class="h-8 w-10 outline-none"
              fill="none"
              viewBox="0 0 24 24"
              stroke="white"
              stroke-width="1"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
              />
            </svg>
          </button>
          <button class="mb-3" v-show="share" @click="close">
            <share-svg />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ShareIt from '../shared/ShareIt.vue'
import PlaySvg from '../svg/PlaySvg.vue'
import ShareSvg from '../svg/ShareSvg.vue'
export default {
  props: ['asset', 'firstEposide'],
  components: { ShareIt, PlaySvg, ShareSvg },
  data () {
    return {
      s3: 'https://d1p7gwkjs434q8.cloudfront.net/series/',
      share: false,
      loaded: false
    }
  },

  methods: {
    image (asset) {
      if (asset.cover) {
        return this.s3 + asset.cover
      } else {
        const images = require.context('@/assets/', false, /\.webp$/)
        return images('./' + 'default.webp')
      }
    }, /////end of image
    async shareApi () {
      const title = window.document.title
      const url = window.document.location.href
      const text = this.asset.desc
      const resultPara = null
      const shareData = {
        title: title,
        text: text,
        url: url
      }
      if (navigator.share) {
        try {
          await navigator.share(shareData)
          resultPara.textContent = 'MDN shared successfully'
        } catch (error) {
          resultPara.textContent = `Error: ${error}`
        }
      } else {
        return (this.share = true)
      }
    },
    close () {
      return (this.share = false)
    }
  }
}
</script>

<style scoped>
.btn-bg {
  background-color: #9d2890;
}
*:focus-visible {
  outline: none;
}

.carousel-item {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
