<template>
  <div class="mt-2" dir="rtl">
    <swiper
      :grabCursor="true"
      :modules="modules"
      :slidesPerView="'auto'"
      :spaceBetween="20"
      :freeMode="true"
      :centeredSlides="false"
      :navigation="{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }"
    >
      <swiper-slide v-for="(item, index) in items" :key="item.id">
        <card-basic :item="item" :className="className" :index="index" />
      </swiper-slide>

      <!-- Add navigation buttons -->
      <div class="swiper-button-prev" ></div>
      <div class="swiper-button-next" ></div>
    </swiper>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation"; // Import navigation styles

// import required modules
import { Mousewheel, Keyboard,Navigation } from "swiper";

import CardBasic from "./CardBasic.vue";

export default {
  props: ["items", "className"],
  components: {
    Swiper,
    SwiperSlide,
    CardBasic,
  },
  setup() {
    return {
      modules: [Mousewheel, Keyboard,Navigation ], // Add Navigation module here
    };
  },
};
</script>

<style scoped>
.swiper {
  width: 100%;
  height: 240px;
}
@media only screen and (max-width: 960px) {
  .swiper {
    width: 100%;
    height: 135px;
  }
  /* Hide navigation buttons on non-desktop screens */
  .swiper-button-prev, .swiper-button-next {
    display: none;
  }
}
.swiper-slide {
  text-align: center;
  font-size: 18px;
  width: 480px !important; /* Adjusted width for desktop */
  height: 100%; /* Full height */
  padding: 0; /* No padding */
  margin: 0; /* No margin */
}

/* Adjustments for mobile screens */
@media only screen and (max-width: 960px) {
  .swiper-slide {
    width: 240px !important; /* Adjusted width for mobile */
  }
}

/* Make the image take full width and height of the swiper-slide */
.swiper-slide img {
  display: block;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  object-fit: cover; /* Cover the entire area without stretching */
}
/* Navigation buttons styles */
.swiper-button-prev, .swiper-button-next {
  color: #FFF; /* Change the color to suit your design */
}
/* Navigation buttons styles with overlay */
.swiper-button-prev, .swiper-button-next {
  position: absolute;
  top: 45%;
  z-index: 99999;
  font-size: 20px !important;
  width: 80px; /* Width of the arrow button */
  height: 80px; /* Height of the arrow button */
  margin-top: -25px; /* Half of the height to center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  color: #fff; /* White arrow color */
  border-radius: 50%; /* Circular shape */
 
}

.swiper-button-next::after, .swiper-button-prev::after {
  font-size: 20px !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
}

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled {
  opacity: 0 !important;
  cursor: auto;
  pointer-events: none;
}


</style>
