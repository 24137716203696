<template>
  <div class="mx- mb-2">
    <HomeLayout :data="false"></HomeLayout>
    <div class="flex justify-center">
      <div class="my-3">
        <h6 class="text-whtie text-lg md:text-3xl text-white">
          تصفح التصنيفات
        </h6>
        <div class="border border-color border-b-4"></div>
      </div>
    </div>
    <div class="px-4">
      <div  class="flex flex-wrap my-3 md:mx-12" dir="rtl">
        <cate-card
          v-for="(genre, index) in genresData"
          :index="index"
          :key="genre.id"
          :genre="genre"
        />
      </div>
    </div>
  </div>
</template>

<script>
import HomeLayout from '@/components/HomeLayout.vue'
import CateCard from '@/components/cards/CateCard.vue'
import { useGenresStore } from '@/stores/genres'
import { mapActions, mapState } from 'pinia'
export default {
  components: { HomeLayout, CateCard },
  data () {
    return {
      loaded: false
    }
  },
  async created () {
    this.loaded = true
    try {
      await this.showGenresData(1, 10)
      this.loaded = false
    } catch (e) {
      this.e
    }
  },
  computed: {
    ...mapState(useGenresStore, ['genresData'])
  },

  methods: {
    ...mapActions(useGenresStore, ['showGenresData'])
  }
}
</script>

<style></style>
