<template>
       <div class="media-parent   ">
           <div class="media-child">
            <video   id="my_player" class="video-js vjs-fluid" :poster="require('@/assets/audio.jpg')" controls preload="auto" playsinline  >
<source :src="require('@/assets/molly.mp3')" type="audio/mp3">
</video>
    </div>
   </div>
   
   </template>
   
   <script>
   /* global videojs */
   
   export default {
       mounted() {
       this.initializePlayer();
       
     },
     methods: {
       initializePlayer() {
   
         const player = videojs('my_player',{language: 'ar'});
         player.nuevo({
       themeColor: '#9d2890',
       video_id: 'x-11',
           resume: true ,
       shareMenu:false,
       zoomMenu:false,
       buttonRewind :false
   
         });
      
         var audio_info = {title:"Molly Song", artist:"Maciej", cover:"https://placehold.co/600x400", album:"Love Songs", year:"2020", genre:"Pop"}
	player.nuevo({ audioInfo: audio_info });
	player.visualizer({video:true,caps:true});
        },
     },
     beforeUnmount() {
       if (this.player) {
         this.player.dispose();
       }
     },
   }
   </script>
   
   <style>
   .video-js .vjs-big-play-button{
     background-color:#9d2890 !important ;
   }
   
   
   
   
     /* If you want to change the progress bar color */
     .video-js .vjs-control-bar .vjs-progress-holder .vjs-play-progress,
     .video-js .vjs-control-bar .vjs-volume-panel .vjs-volume-level {
       background-color: #9d2890 !important; /* Replace #yourProgressColor with your desired color */
     }
   </style>