<template>
  <div class="flex justify-center items-center rounded-lg mx-2 mt-2  md:mt-6  relative ">
    <div class="absolute top-6  md:w-4/12 w-full bg-yellow-400  rounded-md h-auto z-50 px-6 mx-12 p-4 " v-show="showMessage">
      <div class="flex justify-between space-x-2 ">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" class="w-6 h-6 cursor-pointer" @click="closeAlert" >
  <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
</svg>

  <div class="flex  space-x-1">
          
<span class="text-gray-300">تنبيه</span>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#FFF" class="w-6 h-6">
  <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
</svg>  
  </div>
       </div>
      <div class="text-right text-gray-200 text-md mt-2 mr-3 ">
<span>    {{ " للاستمتاع بأفضل تجربة للمنصة استخدم متصفح Chrome أو Safari" }}
</span>
    </div>
    </div>
    <div
      class=" md:w-5/12 w-full   rounded-md  h-auto md:p-0 p-2    text-white md:border md:border-gray-600  ">
      <div class="overflow-auto ">


        <auth-carsouel class="" />
        <div class="flex flex-row justify-center my-1.5">
          <div class="md:auto text-center border-b-4 border-[#9d2890]">
            <p class="text-white pb-0.5 px-2 text-[25px]">Nasna TV</p>
          </div>
        </div>
        <div class="my-2 text-center flex justify-center">
          <p class="text-gray-400 text-sm max-w-md text-center">
            استمتع بالمشاهدة والاستماع للمسلسلات والبرامج السودانية في أي وقت، على أي جهاز بدون فواصل أو إعلانات.
          </p>
        </div>
        <form class="mt-2 ">
          <legend>

            <div class="m-3 relative md:w-96 mx-auto " dir="ltr">
              <h1 class=" text-md text-center font-Tajawal text-[#9d2890]"> أدخل رقم الهاتف للمتابعة </h1>

             
              <phone-number/>
              
            </div>
           
          </legend>
        </form>
        <div class="m-3 relative md:w-96 mx-auto mt-8 " dir="rtl">

          <div class="flex justify-between items-center">
            <hr class="flex-grow border-gray-600">
            <p class="mx-3 text-gray-300">
              أو قم بالمتابعة عبر</p>
            <hr class="flex-grow border-gray-600">
          </div>
          <div class="my-3">
            <div class="flex flex-col gap-3 ">
              <button @click="redirectToFacebook"
                class="inline-flex justify-center items-center gap-2 w-full bg-blue-600 text-gray-100 rounded-md py-3">

                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.0" x="0px"
                  y="0px" width="50" height="50" viewBox="0 0 50 50" style="width: 20px; height: 20px; " fill="#FFF" class="">
                  <path
                    d="M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3 c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z">
                  </path>
                </svg>
                <span> متابعة عبر فيسبوك</span>

              </button>
              <button @click="redirectToGoogle"
                class="inline-flex justify-center items-center gap-2 w-full bg-white text-gray-900 rounded-md py-3">
                <img src="@/assets/google.png" class="object-cover w-6 h-auto" />
                <span> متابعة عبر جوجل</span>

              </button>
             
            </div>
          </div>
          <div class="flex items-center justify-center gap-x-2 relative z-50">
            <a href="https://sites.google.com/view/nasna-tv"> <small class="text-gray-600 underline">سياسة
                الخصوصية</small></a>
            <a href="https://sites.google.com/view/nasnatv"> <small class="text-gray-600 underline">
                الشروط والأحكام</small></a>

          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AuthCarsouel from "@/components/shared/AuthCarsouel.vue";
import { HTTP } from '@/http-common';
import PhoneNumber from '@/components/PhoneNumber.vue';


export default {
  components: {
    AuthCarsouel,
    PhoneNumber
  },
  data() {
 
       return {
      user: {
        email: '',
        password: ''
      },
      google: "/auth/google/redirect",
      faceBook: "/auth/facebook/redirect",
      showMessage:false
    }
  },
  mounted () {
    this.detectEnvironmentAndRedirect();
  },
  methods: {
    closeAlert(){
      this.showMessage=false
      // window.open("https://watch.nasna.tv/", '_system');
    },
    detectEnvironmentAndRedirect() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isFacebook = userAgent.indexOf('FBAN') > -1 || userAgent.indexOf('FBAV') > -1;
    const isTwitter = userAgent.indexOf('Twitter') > -1;
    const isInstagram = userAgent.indexOf('Instagram') > -1;

    if (isFacebook || isTwitter || isInstagram) {
        this.showMessage = true;
    }

    console.log(userAgent, isFacebook, isTwitter, isInstagram);
}

,
    async redirectToFacebook() {
      try {
        await HTTP.get(`${this.faceBook}`)
          .then((response) => {
            if (response.data.success) {
              window.location.href = response.data.redirectURL
            }
          })
      } catch (error) {

        console.error("Failed to get data for facebook callback", error);
      }
    },
    async redirectToGoogle() {
      try {
        await HTTP.get(`${this.google}`)
          .then((response) => {
            if (response.data.success) {
              window.location.href = response.data.redirectURL
            }
          })
      } catch (error) {

        console.error("Failed to get data for google callback", error);
      }
    }
  }
}

</script>



<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: right;
}

.font-small {
  font-size: 16px;
}
</style>
