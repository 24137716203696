<template>
  <div class="flex justify-center items-center rounded-lg mx-2 mt-2 md:mt-6 relative">
  
    <!-- Main Content -->
    <div
      class="md:w-5/12 w-full rounded-md h-auto md:p-0 p-2 text-white md:border md:border-gray-600"
    >
      <div class="overflow-auto">
        <auth-carsouel />
        <div class="flex flex-row justify-center my-1.5">
          <div class="md:auto text-center border-b-4 border-[#9d2890]">
            <p class="text-white pb-0.5 px-2 text-[25px]">Nasna TV</p>
          </div>
        </div>
        <div class="my-2 text-center flex justify-center">
          <p class="text-gray-400 text-sm max-w-md text-center">
            استمتع بالمشاهدة والاستماع للمسلسلات والبرامج السودانية في أي وقت، على أي جهاز بدون فواصل أو إعلانات.
          </p>
        </div>
     
      </div>
      <div class="my-1 ">
          <h1 class="text-[#9d2890] text-md mb-3 font-Tajawal">ادخل رمز التأكيد الذي وصلك في رسالة نصية</h1>
          <div class="flex justify-center items-center ">
            <MazInputCode v-model="code"  :size="size" class=""  />
          </div>
          <div class="flex flex-col justify-center items-center gap-3 my-3 relative z-50">
            <div class="text-center mt-2">
    </div>
            <button class="md:w-96 w-full bg-[#9d2890] py-3 px-6 rounded-md inline-flex justify-center items-center gap-2" @click="submitCode" :disabled="!isCodeFull || loaded" ><span v-if="!loaded">تأكيد</span> 
              <div role="status" v-show="loaded">
        <svg
          class="inline mr-6 w-5 h-5 text-gray-200 animate-spin  fill-red-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="currentFill"
          />
        </svg>
        <span class="sr-only">Loading...</span>
      </div>
            </button>
            <button  class="md:w-96 w-full  py-3 px-6 rounded-md text-lg " @click="resendOTP" :disabled="time > 0">
            <template v-if="time===0"><span class=" text-[#9d2890]"> إعادة إرسال</span>
            </template>
            <template v-else>
              <span class="text-gray-100 text-3xl">{{ formatTime() }}</span>

            </template>
            </button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import MazInputCode from 'maz-ui/components/MazInputCode';
import AuthCarsouel from '@/components/shared/AuthCarsouel.vue';
import { useAuthStore } from '@/stores/auth';
import { mapActions } from 'pinia';
import { useToast } from 'vue-toastification';

export default {
  components: {
    MazInputCode,
    AuthCarsouel,
  },
  data() {
    return {
      code: '',
      showMessage: false, // Controls visibility of the alert message
      canResend: true, // Whether the user can resend the OTP
      time: 60, // 2 minutes in seconds
      size:"sm",
      toast:useToast(),
      loaded:false
    };
  },
  computed: {
    isCodeFull() {
      return this.code.length === 4; // Assuming OTP is 6 digits long
    },
  },
  methods: {
    ...mapActions(useAuthStore,['verifyOtp','subscribeWithPhone','setData']),
    
   async submitCode() {
    this.loaded=true
      // Implement the logic to submit the OTP
      await this.verifyOtp(this.code).then((response)=>{
        if(response.data.success){
          this.toast.success("تم تاكيد رقم الهاتف بنجاح");
          this.setData(response)
          this.$router.push('/');
          this.loaded=false
        }else{
          this.toast.error('رمز التأكيد غير صحيح');

        }
      }) 
      this.loaded=false
    },
   async resendOTP() {
      this.time = 60; // Reset timer
      this.startTimer(); 
      // Implement the logic to resend the OTP
      await this.subscribeWithPhone(localStorage.getItem('userPhoneNumber')).then((response)=>{
        if(response.data.success){
          this.toast.success("تم إرسال رمز التأكيد");
        }else{
          this.toast.error('حدث خطأ ما، حاول مرة أخرى');
        }
      })
      
    },
   
    startTimer() {
      const timerInterval = setInterval(() => {
        if (this.time > 0) {
          this.time--;
        } else {
          clearInterval(timerInterval);
          // Timer finished logic here
        }
      }, 1000);
    },
    formatTime() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },
    closeAlert() {
      this.showMessage = false;
    },
  },
  created() {
    this.startTimer();
  },
};
</script>

<style scoped>
/* Your existing styles */
</style>


  <style scoped>
  .width-height {
    width: 900px;
    height: auto;
  }
  
  .xxsm {
    width: 255px;
  }
  
  .w-btn {
    width: 290px;
    align-self: right;
  }
  
  .btn-color {
    background-color: #9d2890;
  }
  
  .bg-sub {
    background-color: #181b30;
  }
  
  input {
    text-align: center;
    outline: none;
    padding-left: 5px;
    color: aliceblue;
  }
  
  ::-webkit-input-placeholder {
    text-align: center;
  }
  
  :-moz-placeholder {
    text-align: right;
  }
  
  .font-small {
    font-size: 16px;
  }
  </style>
  