<template>
  <div v-if="serie">
    <div class="flex justify-end mx-3 border-b-1 mt-2">
      <div>
        <h6 class="text-lg md:text-2xl font-bold text-white -mb-2">
          {{serie.title }}
        </h6>
        <div class="my-2 border-color border border-b-2"></div>
      </div>
    </div>
    <div class="flex justify-start mx-3 border-b-1 my-2 m-2" dir="rtl">
      <p class="text-gray-600 md:text-sm text-xs font-bold text-right w-128">
        {{ 'تمت إضافته في:  ' + dateForamts(serie) }}
      </p>
    </div>
    <div class="flex justify-end mx-3 border-b-1 my-2 m-2 space-x-3">
      <small class="text-white font-bold text-lg">{{
      serie.genre.title
      }}</small>
      <h3 class="text-color text-lg font-bold">:التصنيف</h3>
    </div>

    <div class="flex justify-start mx-3 border-b-1 my-2 m-2" dir="rtl">
      <p class="text-gray-400 md:text-sm text-xs font-bold text-right w-128">
        {{ serie.desc }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ['serie'],

  components: {},
  methods: {
    dateForamts (serie) {
      if (!serie.createdAt) return 'غير معروف'
      var d = new Date(serie.createdAt),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    }
  }
}
</script>

<style></style>
