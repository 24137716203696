import { ApolloClient, createHttpLink, InMemoryCache, } from "@apollo/client/core";
// import { setContext } from '@apollo/client/link/context';
// import { persistCache } from 'apollo-cache-persist'

// HTTP connection to the API
const httpLink = createHttpLink({
  // nasna absolute uri
  uri: "https://graph.nasna.tv/"

});


// Cache implementation
const cache = new InMemoryCache();


// persistCache({
//   cache,
//   storage: window.localStorage,
// });

// Create the apollo client
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
  // headers: {
  //   authorization: localStorage.getItem('token')
  // }
});

export default apolloClient;
