
<template>
  <div class="flex justify-center rounded-lg mx-2 mt-2 md:mt-6">
    <div class="rounded-md md:w-5/12">
      <div
        class="
          container
          mx-auto
          px-4
          p-1
          bg-sub
          rounded-lg
          shadow-md
          drop-shadow-2xl
          lg:p-2
          rounded-md
          place-items-center
        "
      >
        <auth-carsouel class="rounded-md" />

        <div class="">
          <h5
            dir="rtl"
            class="
              my-3
              text-md md:text-lg text-white
              md:font-bold font-Tajawal
              text-center
            "
          >
            اشترك واستمتع بمكتبة ضخمة من المحتوى الترفيهي والثقافي المناسب لمزاج
            كل زول سوداني
          </h5>
          <!-- <div  class="border border-b-2 border-pink-500  mx-2 mx-auto mt-1 w-36"></div> -->

          <div class="flex md:justify-center m-3" dir="rtl">
            <ul>
              <li
                class="
                  text-white
                  md:text-md
                  text-sm text-gray-400
                  mr-6
                  my-3
                  flex
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                مكتبة ضخمة من المحتوى الرقمي تحتوى على أكثر من 1500 فيديو
              </li>
              <li
                class="
                  text-white
                  md:text-md
                  text-sm text-gray-400
                  mr-6
                  my-3
                  flex
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                مشاهدة جميع البرامج والحلقات بلا قيود ودون فواصل او إعلانات
              </li>
              <li
                class="
                  text-white
                  md:text-md
                  text-sm text-gray-400
                  mr-6
                  my-3
                  flex
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                تابع حلقاتك المفضلة على جميع الأجهزة
              </li>
              <li
                class="
                  text-white
                  md:text-md
                  text-sm text-gray-400
                  mr-6
                  my-3
                  flex
                "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6 ml-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="white"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
                محتوى متنوع يناسب كل العائلة
              </li>
          
            </ul>
          </div>

          <div class="flex justify-center my-6">
            <button
              type="submit"
              class="
                text-white
                focus:ring-0 focus:outline-none focus:ring-pink-300
                rounded-lg
                text-md
                w-full
                sm:w-auto
                px-5
                py-2.5
                text-center
                btn-color
                w-btn
                text-white
                cursor-pointer
                md:mr-8
                absloute
                z-20
                animate-bounce
                hover:animate-none
              "
              @click="subscribe"
            >
              اشترك الان
            </button>
          </div>

          <div class="text-white hidden" dir="rtl">
            <p
              class="text-center font-small m-2 text-md md:text-lg font-Tajawal"
              style="font-size: 11px; line-height: 15px"
            >
              قيمة الاشتراك اليومي لكل المقاطع والبرامج 120 جنيه فقط شاملة
              الضريبة تجدد تلقائيا عبر شبكة سوداني
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import AuthCarsouel from "@/components/shared/AuthCarsouel.vue";
import { nasna } from "@/http-common";
import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import axios from "axios";

export default {
  components: {
    AuthCarsouel,
  },
  data() {
    return {
      dataRes: null,
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["subscribeAction"]),
    async subscribe() {
      // subscribe end point for getting the url
      await this.subscribeAction();
    }, //end of subscrbtion method
    async redirectToLandingPage(url) {
      axios
        .post(`${url}`)
        .then(async (res) => {
          console.log(res);
        })
        .catch((error) => console.log(error));
    },
    async checkSubescriptionStatus(dataRes) {
      await nasna
        .post("/auth/subescription/status/check", dataRes)
        .then((res) => {
          console.log(res);
          // if success
          // open portal
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>




<style scoped>
.width-height {
  width: 900px;
  height: auto;
}
.xxsm {
  width: 255px;
}
.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
}

::-webkit-input-placeholder {
  text-align: right;
}

:-moz-placeholder {
  text-align: center;
}
.font-small {
  font-size: 16px;
}
.temporary-bounce {
  -webkit-animation-iteration-count: 3;
  animation-iteration-count: 3;
}
</style>
