<template>
    <div class="flex justify-center rounded-lg mx-2 mt-12">
        <div class="">
            <div class="
            container
            mx-auto
            px-4
            p-1
            bg-sub
            shadow-md
            drop-shadow-2xl
            lg:p-2
            rounded-md
            place-items-center
          ">
                <div class="flex justify-center">
                    <div v-if="isLoading">
                        <div class="z-50 flex justify-center items-center">
                            <div class="
                    animate-spin
                    rounded-full
                    md:h-32 md:w-32
                    h-24
                    w-24
                    border-t-2 border-b-2 border-gray-100
                  "></div>
                        </div>
                    </div>
                </div>
                <div class="mt-2">
                    <p class="text-md text-white text-center">الرجاء الانتظار</p>
                    <div class="border border-b-2 border-color mx-auto mt-1 w-36"></div>

                    <div class="m-3 relative md:w-96 mx-auto" dir="rtl">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { AUTHTOKEN, ISACTIVESTRIPEUSER, USER } from "@/constants";
import { onMounted, reactive, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

let isLoading = ref(true);
let data = reactive({});


const route = useRoute();
const router = useRouter();
const authProvider = route.params.provider;

onMounted(async () => {
    console.log("Test CMP Mounted");

    console.log("Auth Provider Here is", authProvider);
    /**
     * Construct URL for the callback route.
     */

    const url = new URL(
        `https://api.nasna.tv/api/auth/${authProvider}/callback`
    );

    /**
     * Add the query provided by google.
     */
    url.search = window.location.search;

    /**
     * Send the final request. You can use Axios if you want.
     */

    const response = await fetch(url.toString(), {
        method: "GET",
        headers: {
            Accept: "application/json",
        },
        credentials: "include",
    }).then((response) => response.json());
    // print response
    data = response;

    if (data?.success) {
        window.localStorage.setItem(USER, JSON.stringify(data.user));
        window.localStorage.setItem(AUTHTOKEN, data.token);
        window.localStorage.setItem(ISACTIVESTRIPEUSER, data.user.status);
        isLoading.value = false;
        if(data.newAccount){
            router.push('/success');
        }else{
            router.push('/');
        }
       

    } else {
        console.log(response)
    }
});
</script>

<style></style>