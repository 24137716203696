<template>
  <div class="flex justify-center rounded-lg mx-2 mt-2 md:mt-6 ">
    <div class="md:w-5/12 ">

      <div class="
          container
          mx-auto
          px-4
          p-1
          bg-sub
          shadow-md
          drop-shadow-2xl
          lg:p-2
          rounded-md
          place-items-center
        ">
        <auth-carsouel />
        <div class="">
          <h5 dir="rtl" class="
              my-3
              text-md text-lg text-white
              font-bold font-Tajawal
              text-center
            ">
            قم بالدخول واستمتع بمشاهدة البرامج والقنوات بلا حدود
          </h5>
          <!-- <div  class="border border-b-2 border-pink-500  mx-2 mx-auto mt-1 w-36"></div> -->

          <div class="m-3 relative md:w-96 mx-auto " dir="rtl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9d2890"
              class="w-6 h-6 absolute top-2 right-2">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25" />
            </svg>


            <input type="email" class="
                bg-gray-900
                border border-color
                focus:ring-pink-500 focus:border-pink-500
                text-gray-900 text-sm
                rounded-sm
                w-full
              " v-model="user.email" placeholder="Enter your Email" required />
          </div>

          <div class="flex  flex-col gap-2 justify-center my-6">
            <button type="submit" class="
                text-white
                focus:ring-0 focus:outline-none focus:ring-pink-300
                rounded-lg
                text-md
                w-full
                sm:w-auto
                px-5
                py-2.5
                text-center
                btn-color
                cursor-pointer
                md:w-96 mx-auto
               
                absloute
                z-20
              " @click.prevent="loginAction">
              !دخول الان
            </button>
            <span class="text-white flex justify-center items-center">او</span>
            <button type="submit" class="
                text-white
                focus:ring-0 focus:outline-none focus:ring-pink-300
                rounded-lg
                text-md
                w-full
                sm:w-auto
                px-5
                py-2.5
                text-center
                border border-color
                cursor-pointer
                md:w-96 mx-auto
               
                absloute
                z-20
              "  @click="$router.push('/user/subscriptions')">
              اشترك الان

            </button>
          </div>

          <!-- hello -->
        </div>
      </div>
    </div>
  </div>
</template>



<script>
import AuthCarsouel from "@/components/shared/AuthCarsouel.vue";

import { useAuthStore } from "@/stores/auth";
import { mapActions } from "pinia";
import { useToast } from 'vue-toastification';

export default {
  components: {
    AuthCarsouel,
  },
  data() {
    return {
      user: {
        email: ""
      },
      error: false,
      errorNotSubscribed: false,
      toast: useToast()
    };
  },
  methods: {
    ...mapActions(useAuthStore, ["login", 'setData']),
    async loginAction() {


      // subscribe end point for getting the url
      if (this.user.email) {

        await this.login(this.user).then((response) =>
          this.redirect(response)
        );
      }
    }, //end of subscrbtion method

    close() {
      return (this.error = false);
    },
    redirect(response) {
      console.log(response.data)
      if (response.data.success && response.data.isActive) {

        this.toast.success("Logged in Successfully")
        this.setData(response)
        this.$router.push({ name: "home" });
      } else {
        this.toast.error('Failed to login')
        this.$router.push({ name: "user.subscriptions" });
      }
    },
  },
  created() {

  }
};
</script>

<style scoped>
.width-height {
  width: 900px;
  height: auto;
}

.xxsm {
  width: 255px;
}

.w-btn {
  width: 290px;
  align-self: right;
}

.btn-color {
  background-color: #9d2890;
}

.bg-sub {
  background-color: #181b30;
}

input {
  text-align: center;
  outline: none;
  padding-left: 5px;
  color: aliceblue;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

.font-small {
  font-size: 16px;
}
</style>
