<template>
  <div class="lg:container md:mx-auto">
    <HomeLayout :data="false"></HomeLayout>
    <div class="flex overflow-hidden mx-auto">
      <div
        class="flex justify-center items-center w-full h-auto lg:h-128 md:mx-4 mt-1"
        style=""
      >
        <div
          class="
            flex
            justify-center
            items-center
            md:w-5/6
            w-full
            h-80
            sm:h-128
            md:h-128 md:mx-auto
          "
          style="background-color: #15182b"
        >
          <ArtPlayer
            @get-instance="getInstance"
            :option="option"
            :style="style"
            :isLive="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomeLayout from "@/components/HomeLayout.vue";
import { useLivesStore } from "@/stores/live";
import { mapState, mapActions } from "pinia";
import ArtPlayer from "@/components/ArtPlayer";

export default {
  components: { HomeLayout, ArtPlayer },
  data() {
    return {
      option: {  url: "https://live.nasna.tv/hls/stream.m3u8"},
      style: { width: "100%", height: "100%", margin: "5px 0 0 0" },
    };
  },
  computed: {
    ...mapState(useLivesStore, ["live"]),
  },

  async created() {
    await this.showLive(this.$route.params.id);
  },
  methods: {
    ...mapActions(useLivesStore, ["showLive"]),
    getInstance(art) {
      console.log(art);
    },
  },
};
</script>

<style>
</style>