<template>
  <div ref="artRef" class="art-app"></div>
</template>

<script>
import Artplayer from "artplayer";
import Hls from "hls.js";

export default {
  data() {
    return {
      instance: null,
    };
  },

  props: {
    option: { type: Object, required: true },
    previous: { type: Object },
    next: { type: Object },
    isLive: {
      type: Boolean,
      default: false,
    },
  },
  // hu
  mounted() {
    setTimeout(() => {
      this.instance = new Artplayer({
        ...this.option,
        container: this.$refs.artRef,
        playsInline: true,
        volume: 0.5,
        theme: "#BF10BF",
        airplay: true,
        whitelist: ["*"],
        autoOrientation: true,
        backward: true,
        autoplay: true,
        autoMini: true,
        setting: true,
        loop: false,
        fastForward: true,
        lang: 'ar',
        url: this.option.url,
        isLive: this.isLive,
        playbackRate: true,
        aspectRatio: true,
        fullscreen: true,
        miniProgressBar: true,
        hotkey: true,
        autoPlayback: true,
        controls: [
          {
            position: 'left', // This will place the control to the right of the existing controls
            index: 0, // This will place the control right after the play button
            html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFF" class="w-6 h-6">
  <path d="M5.055 7.06C3.805 6.347 2.25 7.25 2.25 8.69v8.122c0 1.44 1.555 2.343 2.805 1.628L12 14.471v2.34c0 1.44 1.555 2.343 2.805 1.628l7.108-4.061c1.26-.72 1.26-2.536 0-3.256l-7.108-4.061C13.555 6.346 12 7.249 12 8.689v2.34L5.055 7.061Z" />
</svg>

`,
            click: () => {
              this.handleNext();
            },
          },
          {
            position: 'left', // This will place the control to the left of the existing controls
            index: 2, // This will place the control right before the play button
            html: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFF" class="w-6 h-6">
  <path d="M9.195 18.44c1.25.714 2.805-.189 2.805-1.629v-2.34l6.945 3.968c1.25.715 2.805-.188 2.805-1.628V8.69c0-1.44-1.555-2.343-2.805-1.628L12 11.029v-2.34c0-1.44-1.555-2.343-2.805-1.628l-7.108 4.061c-1.26.72-1.26 2.536 0 3.256l7.108 4.061Z" />
</svg>

`,
            click: () => {

              this.handlePrevious();
            },
          },

        ],


        customType: {
          m3u8: function (video, url) {
            if (Hls.isSupported()) {
              const hls = new Hls();
              hls.loadSource(url);
              hls.attachMedia(video);
            } else {
              const canPlay = video.canPlayType(
                "application/vnd.apple.mpegurl"
              );
              if (canPlay === "probably" || canPlay == "maybe") {
                video.src = url;
              } else {
                this.instance.notice.show = "Does not support playback of m3u8";
              }
            }
          },
        },
      });

      this.$nextTick(() => {
        this.option;
        this.$emit("get-instance", this.instance);
      });
    }, 1000);
  },
  methods: {
    handlePrevious() {
      if(!this.previous) return false;
      this.$router.push({ name: "watch", params: { id: this.previous.id } })

    },
    handleNext() {
      if(!this.next) return false;
      this.$router.push({ name: "watch", params: { id: this.next.id } })
    },
  },
  beforeUnmount() {
    if (this.instance && this.instance.destroy) {
      this.instance.destroy(false);
    }
  },
};
</script>

<style scoped></style>
