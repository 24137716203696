import { defineStore } from "pinia";
import { nasna } from "../http-common.js"
import { AUTHKey, AUTHTOKEN, ISACTIVESTRIPEUSER, USER } from "@/constants.js";


export const useAuthStore = defineStore("auth", {
    state: () => {
        return {
            isLoggedin: Boolean, // check user status 
            user: JSON.parse(localStorage.getItem("user")), // user data to be stored 
            token: "",

        }
    },// end of state
    getters: {
        getUser: (state) => state.user,
        isAuthenticated: (state) => state.user != undefined,
        getPhone: (state) => state.phone

    }, //end of getters 
    actions: {
        subscribeAction() {
            return new Promise((resolve, reject) => {
                try {
                    let initPaymentResult = nasna.post(`/auth/mobily/subscriptions/initPayment`);
                    resolve(initPaymentResult);
                } catch (error) {
      
                    reject(error);
                }
            });
        },
        /**
         * auto login the user
         * @param sessionId
         * subscribe 
         */
        async getUserFromSession(sessionId) {
            return new Promise((resolve, reject) => {
                nasna.get(`/stripe/loginBySessionID/${sessionId}`)
                    .then((response) => {

                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            console.log(error.response.data)
                            resolve(error.response)
                        } else {
                            reject(error);
                        }

                    });
            });
        },
        /**
         * login user for authentication purpose 
         */
        async login(data) {
            return new Promise((resolve, reject) => {
                nasna.post('/stripe/login', { email: data.email })
                    .then((response) => {

                        resolve(response)
                    })
                    .catch((error) => {
                        if (error.response && error.response.data) {
                            resolve(error.response)
                        } else {
                            reject(error);
                        }

                    });

            });

        },
        
        manageAccount(data) {

            return new Promise((resolve, reject) => {
                nasna.post('/stripe/manage', data)
                    .then((response) => {
                        console.log(response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });


        },
        subscribeWithPhone(msisdn) {
            const data = {
                msisdn:msisdn
            }
    
            return new Promise((resolve, reject) => {
                nasna.post('/auth/msisdn/otp', data)
                    .then((response) => {
                        console.log(response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });


        },
        verifyOtp(otp) {
            let msisdn = localStorage.getItem('userPhoneNumber');
            const data = {
                msisdn:msisdn,
                otp:otp
            }
    
            return new Promise((resolve, reject) => {
                nasna.post('/auth/msisdn/verify', data)
                    .then((response) => {
                        console.log(response.data)
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error);
                    });

            });


        },
        async setData(response) {

            if (response.data.success) {
                
                window.localStorage.setItem(USER, JSON.stringify(response.data.user));
                window.localStorage.setItem(AUTHTOKEN, response.data.token);
                window.localStorage.setItem(AUTHKey, response.data.key);
                window.localStorage.setItem(ISACTIVESTRIPEUSER,  response.data.isActive);

            } else {
                this.wipeUserData(response)
            }

        },



        wipeUserData(response) {
            if (!response.data.success) {
                window.localStorage.removeItem(USER),
                    window.localStorage.removeItem(AUTHTOKEN),
                    window.localStorage.removeItem(AUTHKey),
                    window.localStorage.removeItem(ISACTIVESTRIPEUSER)

            }
        },




    }
})