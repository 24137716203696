<template>
  <div class="rounded-lg h-full w-full pb-2" @click="$router.push({name:'watch',params:{id:item.id}})">
    <div class="absolute left-2 md:left-6 top-3" v-show="item.label">
      <div class="flex justify-between space-between h-7">
        <div
          class="align-baseline nowrap rounded-sm text-center py-0.3 mt-2 mr-1 text-sm px-3 inline font-normal btn-color md:text-md text-white truncate">
          {{ item.label }}
        </div>
      </div>
    </div>
    <div :class="currentPlaying === item.id ? 'overlay-item' : ''"></div>
      <playing-svg :currentPlaying="currentPlaying" :item="item"/>
      <img
        class="rounded-lg swiper-lazy  "
        :src="imageData(item.image)"
        width="100"
        height="100"
        :alt="item.title"
        style="object-fit: cover !important; height: 85%"
        @click="$router.push({name:'item.id',params:{id:item.id}})"
      />
      <div class="">
        <h5
          class="md:mt-3 text-md font-bold text-center tracking-tight text-white"
        >
          {{ item.title }}
        </h5>
      </div>
  </div>
</template>

<script>
import PlayingSvg from '../svg/PlayingSvg.vue'
export default {
  components: { PlayingSvg },
  props: ['item', 'index', 'image', 'currentPlaying'],
  data () {
    return {
    s3: 'https://d1p7gwkjs434q8.cloudfront.net/eposides/'
    }
  },
   methods: {
    imageData (image) {
      return this.s3 + image
    }
  },
  created(){
  }
}
</script>

<style scoped>
.overlay-item {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0px;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  right: 0%;
  border-radius: 8px;
}
.stroke-dotted {
  opacity: 1;
  stroke-dasharray: 8, 10;
  stroke: #d50055;
  stroke-width: 4px;
  transform-origin: 50% 50%;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
  transition: opacity 1s ease, stroke-width 1s ease;
}
.svg-icon {
  position: absolute;
  right: 40%;
  top: 35%;
}

@media only screen and (max-width: 960px) {
  .overlay-item {
  width: 100%;
  height: 81%;
}
  .svg-icon {
    position: absolute;
    right: 33%;
    top: 30%;
  }
}
</style>
