<template>
  <div class="rounded-lg md:h-[270px] my-4 px-2 lg:w-1/4 md:w-[480px] sm:w-1/2 w-full relative z-50 cursor-pointer"  @click="$router.push({name:'watch',params:{id:item.id}})">
      <img
        ref="image"
        class="rounded-lg w-full cursor-pointer relative z-50"
        :src="defaultImage"
        :data-src="imageData(item.image)"
        width="100"
        height="100"
        :alt="item.title"
        style="object-fit: cover !important; height: 85%;z-index: 9999;"
       
      />
      <div class="mt-2">
        <h5
          class="mb-2 text-md font-bold text-center tracking-tight text-white text-[24px]"
        >
          {{ item.title }}
        </h5>
      </div>
  </div>
</template>

<script>
export default {
  components: {  },
  props: ['item', 'index', 'image', 'currentPlaying'],
  data () {
    return {
s3: 'https://d1p7gwkjs434q8.cloudfront.net/eposides/' ,
     defaultImage: require('@/assets/placeholder-wide.webp'),
      observer: null
    }
  },
  mounted () {
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null,
      rootMargin: '0px',
      threshold: 0.5 // Adjust as needed
    })

    this.observer.observe(this.$refs.image)
  },
  methods: {
    handleIntersection (entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target
          img.src = img.dataset.src // Add this line to set the src attribute
          this.observer.unobserve(img)
        }
      })
    },
    imageData (image) {
      return this.s3 + image
    }
  }
}
</script>

<style scoped>
.overlay {
  width: 100%;
  height: 85%;
  position: absolute;
  top: 0px;

  background-color: rgba(0, 0, 0, 0.4);
  z-index: 50;
  right: 0%;
  border-radius: 8px;
}
.stroke-dotted {
  opacity: 1;
  stroke-dasharray: 8, 10;
  stroke: #d50055;
  stroke-width: 4px;
  transform-origin: 50% 50%;
  -webkit-animation: spin 4s infinite linear;
  animation: spin 4s infinite linear;
  transition: opacity 1s ease, stroke-width 1s ease;
}
.svg-icon {
  position: absolute;
  right: 40%;
  top: 35%;
}

@media only screen and (max-width: 960px) {
  .svg-icon {
    position: absolute;
    right: 33%;
    top: 30%;
  }
}


.image-container {
  height: 85%;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>
