<template>
  <nav class="w-full md:h-36 h-16">
    <div
      class="lg:px-12 px-4 h-full mx-auto flex justify-between items-center sticky z-40 sm:bg-mobile shadow-menu"
      style="
       
      "
    >
      <div class="z-30">
        <div class="flex flex-wrap justify-between items-center">
          <!-- Dropdown menu -->
          <profile-view />
          <!--end Dropdown menu -->
          <div class="flex justify-center">

            <div class="relative hidden md:block ml-2">
              <!-- This is an example component -->
              <SearchView />
            </div>
          </div>
        </div>
      </div>
      <!-- Logo Here -->
      <div class="md:order-2 logo z-40 mr-2">
        <router-link to="/" class="">
          <img
            src="@/assets/logo-1.webp"
            class="ml-3"
            alt="Nasan Logo"
            width="100"
            height="100"
          />
        </router-link>
      </div>

      <div class="md:ml-auto">
        <!-- Menu links here -->
        <ul
          id="menu"
          class="hidden fixed top-0 right-0 px-10 py-16 bottom-20 z-30 md:relative md:flex md:p-0 md:bg-transparent md:flex-row lg:-space-x-1 duration-700 divider-x divide-gray-700"
          dir="rtl"
        >
          <li class="flex justify-start items-center md:hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#9d2890"
              class="w-6 h-6 md:hidden"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>

            <router-link
              to="/"
              class="block py-2 pr-4 lg:text-[20px] text-md pl-3 md:font-bold text-white"
            >
              الرئيسية</router-link
            >
          </li>
          <hr />
          <li class="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9d2890"
              class="w-6 h-6 md:hidden">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>

            <router-link
              to="/categories"
              class="block py-2 pr-4 lg:text-[20px] text-md pl-3 md:font-bold text-white"
            >
              تصفح التصنيفات</router-link
            >
          </li>
          <hr />

          <hr />
          <li class="flex justify-start items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#9d2890"
              class="w-6 h-6 md:hidden">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg>

            <router-link
              to="/brows/programs"
              class="block py-2 pr-4 lg:text-[20px] text-md pl-3 md:font-bold text-white"
              >البرامج والمسلسلات</router-link
            >
          </li>
          <hr class="bg-red-400" />

          <li class="flex justify-start items-center hidden">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#9d2890"
              class="w-6 h-6 md:hidden"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
              />
            </svg>

            <router-link
              to="/brows/channels"
              href="#"
              class="block py-2 pr-4 lg:text-[20px] text-md pl-3 md:font-bold text-white"
            >
              القنوات
              <small
                class="text-white bg-red-600 rounded-sm px-2 py-1.5 text-xs"
                >بث مباشر</small
              >
            </router-link>
          </li>
          <hr />
        </ul>

        <!-- This is used to open the menu on mobile devices -->
      </div>
    </div>
  </nav>
</template>

<script>
import ProfileView from './shared/ProfileView.vue'
import SearchView from './shared/SearchView.vue'
export default {
  components: { ProfileView, SearchView },

  name: 'MobileView',
  data () {
    return {}
  },
  methods: {
    toggleMenu () {
      var menu = document.getElementById('menu')
      menu.classList.toggle('hidden')
      menu.classList.toggle('w-full')
      menu.classList.toggle('h-full')
      menu.classList.toggle('order-btn')
    }
  }
}
</script>

<style>
/* .bg-mobile{
    background-color: rgba(0, 0, 0, 0.1);
  } */

input[type='text']::placeholder {
  text-align: right;
}
focus:input[type='text'] {
  outline: none;
  border: none;
  outline-width: 0;
}
.shadow-menu{
  background: linear-gradient(
          to top,
          rgb(21, 24, 43, 0) 0%,
          rgb(21, 24, 43, 100)
        );
}
</style>
