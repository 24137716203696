<template>
  <div class="md:mx-auto mb-2">
    <!-- <carsouel-view /> -->
    <HomeLayout :data="true">
      <div>
        <carousel-custom :interval="6000" controls indicators></carousel-custom>
      </div>
    </HomeLayout>


    <!-- end of gird section -->
    <div v-show="MostWatchedData">
      <div class="flex justify-between md:my-12 my-4 mr-8 md:mr-[51px]" dir="rtl">
        <MostWatched class="md:mr-0 -mr-4" />
      </div>
      <!-- end of title and buton tabs -->
      <!-- start of grid section -->
      <div class="mx-auto mr-8 lg:mr-12 my-3 " dir="rtl">
        <CardSliders :items="getMostWatched" />
      </div>
    </div>
    <!-- start of title and buton tabs -->
    <div class="flex md:my-12 my-4 mr-8 md:mr-[51px]" dir="rtl">
      <SeriesHeader class="md:mr-0 -mr-4" />
    </div>
    <!-- end of title and buton tabs -->
    <!-- start of grid section -->
    <div class="mx-auto mr-8 md:mr-[51px] my-3" dir="rtl">
      <SeriesCard :items="seriesData" />
    </div>
    <!-- end of gird section  test-->

    <!-- start of genres -->
    <div v-for="genre in getHomeGenresData" :key="genre.id">
      <div v-if="genre.eposides.length > 0">
        <div class="flex justify-between md:my-12 my-4 mr-8 md:mr-[51px]" dir="rtl">
          <GenreHeader :loaded="loaded" :genre="genre" class="md:mr-0 -mr-4" />

          <div class="ml-6 flex mt-2">
            <span>
              <router-link class="text-color text-md font-bold" :to="`/categories/show/${genre.id}`">تصفح
                الكل</router-link></span>
          </div>
        </div>
        <div class="mx-auto mr-8 lg:mr-12 my-3 " dir="rtl">
          <CardSliders :items="genre.eposides" />
        </div>
      </div>
    </div>
    <!-- start of genres -->
    <!-- end of title and buton tabs -->
    <!-- start of title and buton tabs -->

    
    <!-- end of gird section -->
  </div>
</template>

<script>
// store data
import { mapState, mapActions } from 'pinia'
import { useGenresStore } from '@/stores/genres'
import { useEposidesStore } from '@/stores/eposides'
import { useLivesStore } from '@/stores/live'
import { useSerieStore } from '@/stores/serie'
import HomeLayout from '@/components/HomeLayout.vue'
import CardSliders from '@/components/cards/CardSliders.vue'
import SeriesCard from '@/components/cards/SeriesCard.vue'
import CarouselCustom from '@/components/carousel/CarouselCustom.vue'
import MostWatched from '@/components/home/MostWatched.vue'
import GenreHeader from '@/components/home/GenreHeader.vue'
import SeriesHeader from '@/components/home/SeriesHeader.vue'

export default {
  // data section
  data() {
    return {
      loaded: false,
      title: '',
      page: 1,
      MostWatchedData: false
    }
  },
  /**
   * home required components
   *
   */
  components: {
    HomeLayout,
    CardSliders,
    SeriesCard,
    CarouselCustom,
    MostWatched,
    GenreHeader,
    SeriesHeader
  },
  mounted() {
    addEventListener('scroll', this.handleScroll, true)
  },
  computed: {
    ...mapState(useGenresStore, [
      'genresData',
      'getHomeGenresData',
      'getLength'
    ]),
    ...mapState(useEposidesStore, ['eposidesData']),
    ...mapState(useLivesStore, ['livesData']),
    ...mapState(useSerieStore, ['seriesData', 'getMostWatched'])
  },

  methods: {
    ...mapActions(useGenresStore, ['showGenresData', 'showHomeGenres']),
    ...mapActions(useEposidesStore, ['showEposidesData']),
    ...mapActions(useLivesStore, ['showLivesData']),
    ...mapActions(useSerieStore, ['showHomeSeries', 'showMostWatched']),
    handleScroll() {
      window.onscroll = () => {
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight >=
          document.documentElement.offsetHeight - 50
        if (
          bottomOfWindow &&
          this.getLength > this.getHomeGenresData.length &&
          navigator.onLine
        ) {
          this.MostWatchedData = false
          this.showHomeGenres(this.page++)
        } else if (this.getLength <= this.getHomeGenresData.length) {
          this.MostWatchedData = true
        } else {
          return
        }
      }
    },
    timeData() {
      // var hours = 1; // to clear the localStorage after 1 hour
      // (if someone want to clear after 8hrs simply change hours=8)
      var now = new Date().getTime()
      var setupTime = localStorage.getItem('setupTime')
      localStorage.setItem('mane', 'hello')
      if (setupTime == null) {
        localStorage.setItem('setupTime', now)
      } else {
        if (now - setupTime > 1000) {
          localStorage.clear()
          alert('local')
        }
        console.log(now, setupTime, now - setupTime)
      }
    }
  },

  async created() {
    this.loaded = true
    try {
      await this.showGenresData()
      await this.showEposidesData()
      await this.showLivesData()
      await this.showHomeSeries(1, 6)
      await this.showMostWatched(6)
      await this.showHomeGenres(this.page)

      this.loaded = false
    } catch (error) {
      this.loaded = false
      console.error('Failed to get portal data', error)
    }
  }
}
</script>
<style scoped>
.shadow {
  box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
  -webkit-box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
  -moz-box-shadow: 35px -4px 19px 6px rgba(0, 0, 0, 0.56) inset;
}

.border-cover {
  border-color: #191a2c;
}
</style>
